.HomePageFooter {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	height: 290px;
	background-color: #333;
	text-align: initial;
}

.FooterMenu > li {
	display: inline-block;
	margin-right: 2px;
}

.FooterMenu > ul,
.FooterCategory > ul,
.FooterTags > ul {
	padding-left: 0;
	color: #fff;
	list-style: none;
	display: inline-block;
}

.FooterContainer {
	margin: 0 auto;
	max-width: 1280px;
	width: 90%;
}

a {
	text-decoration: none;
}

p {
	display: block;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}

li {
	display: list-item;
	text-align: -webkit-match-parent;
}

@media only screen and (min-width: 993px) {
	.FooterContainer {
		width: 70%;
	}
}

@media only screen and (min-width: 601px) {
	.FooterContainer {
		width: 85%;
	}
}

.FooterRow {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.FooterRow > div {
	flex-basis: 32%;
	margin: 5px;
}

h5 {
	display: block;
	font-size: 0.83em;
	margin-block-start: 1.67em;
	margin-block-end: 1.67em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;
}

h5 {
	font-size: 1.64rem;
	line-height: 110%;
	margin: 0.82rem 0 0.656rem 0;
	text-align: initial;
}

.FooterMenu,
.FooterCategory {
	flex-basis: 21% !important;
}

.FooterTags {
	flex-basis: 32% !important;
}

.FooterTags > ul {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 200px;
}

/* .FooterMenu > ul > li > a {
	text-decoration: none;
	padding-bottom: 8px;
	color: #fff;
	display: block;
	padding: 9px 12px 10px;
	font-size: 20px;
	line-height: 1.28571em;
	font-weight: 700;
	background: none;
} */

/* .FooterCategory > li {
	display: inline-block;
	margin-right: 2px;
}

.FooterCategory > h6 {
	text-align: center;
	padding-left: 36px;
	color: white;
	font-size: 18px;
	margin: 22px auto auto;
}

.FooterCategory > ul > li > a {
	text-decoration: none;
	padding-bottom: 8px;
	color: #fff;
	display: block;
	padding: 2px 12px 2px;
	font-size: 14px;
	line-height: 1.28571em;
	font-weight: 700;
	background: none;
} */

.white-text {
	color: #fff !important;
}

.grey-text.text-lighten-4 {
	color: #f5f5f5 !important;
}
.grey-text {
	color: #9e9e9e !important;
}

.grey-text.text-lighten-3 {
	color: #eee !important;
}
