.HomePageHeader {
	display: flex;
	flex-direction: column;
	flex: 1;
	/* justify-content: center; */
	background-image: url("./main_image.jpg");
	background-repeat: no-repeat;
	padding-top: 30px;
	height: 570px;
	background-color: #333;
	background-size: cover;
	background-position: 50%;
}
.HomePageNav {
	list-style: none;
	display: inline-block;
}

.HomePageNav > li {
	display: inline-block;
	margin-right: 2px;
}

.HomePageNav > li > a {
	border-radius: 0;
	border-bottom: 2px solid transparent;
	padding-bottom: 8px;
	color: #fff;
	display: block;
	padding: 9px 12px 10px;
	font-size: 14px;
	line-height: 1.28571em;
	font-weight: 700;
	background: none;
}

.HomePageHero {
	/* padding-top: 80px;
	 */
	 display: flex;
	 flex-direction: column;
	 justify-content: center;
	 align-items: center;
}

.HomePageIcon {
	text-align: center !important;
}

.HomePageIcon #MainLogo {
	/* background-image: url('./icon.png'); */
	background-size: 160px 80px;
	background-repeat: no-repeat;
	background-position: center;
	font-size: 30px;
	color:white;
	padding: 40px;
}
.HomePageIcon #MainLogo > a {
	color: transparent !important;
}
